import { BrowserRouter, useNavigate } from "react-router-dom";
import { QueryParamProvider, useQueryParam } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import Wettkampf from "./Wettkampf";
import Wettkampfliste from "./Competitions/Wettkampfliste";

import "../App";
import PageHead from "./PageHead/PageHead";
import {
  ParamWkID,
  ParamWkIDType,
  ParamWkOrg,
  ParamWkOrgType,
} from "./helper/queryParamNames";

const InnerRouter = () => {
  const [wettkampfID] = useQueryParam(ParamWkID, ParamWkIDType);
  const [wettkampfOrg] = useQueryParam(ParamWkOrg, ParamWkOrgType);
  const navigation = useNavigate();

  if (!wettkampfID || !wettkampfOrg) {
    return (
      <>
        <PageHead />
        <Wettkampfliste />
      </>
    );
  } else {
    return (
      <>
        <PageHead />
        <Wettkampf nav={navigation} wkID={wettkampfID} wkOrg={wettkampfOrg} />
      </>
    );
  }
};

const Router = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <InnerRouter />
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default Router;
