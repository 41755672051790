import { Checkbox, Row } from "antd";
import React from "react";

const StorageKey = "disableFlags";

export const getDisableFlagValue = () => {
  const disableFlags = localStorage.getItem(StorageKey);
  if (disableFlags === "true") {
    return true;
  }
  return false;
};

const DisableFlagsView = () => {
  const [value, setValue] = React.useState(localStorage.getItem(StorageKey));
  return (
    <Row>
      <Checkbox
        onChange={({ target: { checked } }) => {
          const s = checked.toString();
          localStorage.setItem(StorageKey, s);
          setValue(s);
          window.location.reload();
        }}
        checked={value === "true"}
      >
        Disable Flags
      </Checkbox>
    </Row>
  );
};

export default DisableFlagsView;
