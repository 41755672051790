import { Button } from "antd";
import { FileDoneOutlined } from "@ant-design/icons";

const ImpressumLink = () => {
  return (
    <Button
      key="Impressum"
      href="https://imas-sport.com/impressum.html"
      icon={<FileDoneOutlined />}
    >
      Impressum
    </Button>
  );
};

export default ImpressumLink;
