import React from "react";
import { Image } from "antd";
import baseurl from "../helper/baseurl";
import { useQueryParam } from "use-query-params";
import {
  ParamWkID,
  ParamWkIDType,
  ParamWkOrg,
  ParamWkOrgType,
} from "../helper/queryParamNames";
import IMAS from "../../assets/IMAS.svg";

const WettkampfFooter = () => {
  const [wkID] = useQueryParam(ParamWkID, ParamWkIDType);
  const [wkOrg] = useQueryParam(ParamWkOrg, ParamWkOrgType);
  const [imageOK, setImageOK] = React.useState(0);
  const url = `${baseurl}/${wkOrg}/${wkID}/${wkID}_footer.jpg`;
  const url2 = `${baseurl}/${wkOrg}/footer.jpg`;
  return (
    <div className="CompetitionFooter">
      {imageOK === 0 ? (
        <Image
          preview={false}
          src={url}
          alt="CompetitionFooter"
          style={{ maxHeight: "10vh", objectFit: "contain" }}
          onError={() => {
            setImageOK(1);
          }}
        />
      ) : null}
      {imageOK === 1 ? (
        <Image
          preview={false}
          src={url2}
          alt="CompetitionFooter"
          style={{ maxHeight: "10vh", objectFit: "contain" }}
          onError={() => {
            setImageOK(2);
          }}
        />
      ) : null}
      <Image
        preview={false}
        src={IMAS}
        alt="IMAS logo"
        style={{ maxHeight: "15vh", objectFit: "contain" }}
      />
    </div>
  );
};

export default WettkampfFooter;
