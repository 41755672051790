import { Select, SelectProps } from "antd";

type WettkampfFilterboxNOCType = {
  filterOptions: string[];
  callback: (arg1: string[]) => void;
  filters: string[];
};

const WettkampfFilterboxNOC = ({
  filterOptions,
  callback,
  filters,
}: WettkampfFilterboxNOCType) => {
  const myOptions: SelectProps["options"] = [];
  filterOptions.sort((a, b) => {
    if (a === "INT") {
      return -1;
    }
    if (b === "INT") {
      return 1;
    }
    return a.localeCompare(b);
  });
  filterOptions.forEach((f) => {
    if (f !== "") {
      myOptions.push({
        label: f,
        value: f,
      });
    } else {
      myOptions.push({
        label: "Unkown",
        value: f,
      });
    }
  });
  return (
    <Select
      mode="multiple"
      allowClear
      style={{ width: "100%" }}
      placeholder="national / international"
      onChange={callback}
      options={myOptions}
      value={filters}
    />
  );
};

export default WettkampfFilterboxNOC;
