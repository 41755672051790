import { Avatar } from "antd";
import { memo } from "react";

type FlagType = {
  id: string;
};

const Flag = memo(function Flag({ id }: FlagType) {
  if (!id || parseInt(id, 10) <= 1) {
    return <div></div>;
  }
  const url = "https://results.imas-sport.com/flags/" + id + ".jpg";
  return <Avatar className="BoatFlag" shape="square" src={url} />;
});

export default Flag;
