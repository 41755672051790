import { BackTop } from "antd";

const BackToTop = () => {
  return (
    <div>
      <BackTop />
    </div>
  );
};

export default BackToTop;
