import { Popover, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import version from "../helper/version";
import DisableDayView from "./DisableDayView";
import DisableFlagsView from "./DisableFlagsView";
import ToResultsPHP from "./ToResultsPHP";

type DatalineType = {
  name: string;
  value: string;
};

const Dataline = ({ name, value }: DatalineType) => {
  return (
    <tr>
      <th>
        <Typography.Text strong>{name}</Typography.Text>
      </th>
      <td>
        <Typography.Text>{value}</Typography.Text>
      </td>
    </tr>
  );
};

const AppInfo = () => {
  const localVersion = version();

  return (
    <Popover
      title="App-Info"
      content={
        <div>
          <table>
            <tbody>
              <Dataline
                name="Version: "
                value={localVersion ? localVersion : "?"}
              />
            </tbody>
          </table>
          <DisableDayView />
          <DisableFlagsView />
          <br />
          <ToResultsPHP />
        </div>
      }
    >
      <InfoCircleOutlined />
    </Popover>
  );
};

export default AppInfo;
