import React from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { DarkModeToggle } from "@anatoliygatt/dark-mode-toggle";

const ThemeSwitch = () => {
  const [isDarkMode, setIsDarkMode] = React.useState(
    localStorage.getItem("dark-mode") === "dark"
  );
  const { switcher, status, themes } = useThemeSwitcher();

  const toggleTheme = (isChecked: boolean) => {
    setIsDarkMode(isChecked);
    localStorage.setItem("dark-mode", isChecked ? "dark" : "light");
    switcher({ theme: isChecked ? themes.dark : themes.light });
  };
  if (status === "loading") {
    return null;
  }
  return (
    <div>
      <DarkModeToggle
        mode={isDarkMode ? "dark" : "light"}
        dark="Dark"
        light="Light"
        size="sm"
        onChange={(mode) => {
          toggleTheme(mode === "dark" ? true : false);
        }}
      />
    </div>
  );
};

export default ThemeSwitch;
