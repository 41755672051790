import baseurl, { debugParser } from "./baseurl";
import handleErrors from "./fetchHelper";
import { DataRaceQueryType } from "./myTypes";
import offlineWarning, { removeWarning } from "./offlineWarning";

const parseRacesAll = async (wkID: string, wkOrg: string) => {
  const url =
    baseurl +
    "/API_IMAS_race_all.php" +
    "?wettkampfOrg=" +
    wkOrg +
    "&competition=" +
    wkID;
  try {
    const data = await fetch(url).then(handleErrors);
    const content = await data.text();
    const raceInfo: DataRaceQueryType = JSON.parse(content);
    removeWarning();
    return raceInfo;
  } catch (e) {
    if (debugParser) {
      console.warn(e, url);
    }
    offlineWarning();
    return null;
  }
};

export default parseRacesAll;
