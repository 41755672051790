import moment from "moment";

export type DataBoatType = {
  0: string;
  1: string[] | null;
  rank: string | null;
  bahn: string;
  id_flagge: string;
  timeOffset?: number;
};

export type DataCompetitionType = {
  competition: string;
  pfad: string;
  name: string;
  from: string;
  to: string;
  regattastrecke: string;
  links: string[] | null;
  zeige_flaggen: boolean;
  noc: string;
  region: string;
  sports: string;
  sportsname: string;
  distanz_darstellung: number;
};

type PositionenType = {
  [key: string]: number;
};

export type DataCleanedBoatType = {
  team: string;
  athletes: string[];
};
export const DataCleanedBoatTypeToString = (
  data: DataCleanedBoatType
): string => {
  let str = data.team;
  data.athletes.forEach((a) => (str += a));
  return str;
};

export type DataRaceType = {
  [key: number]: DataBoatType;
  positionen: PositionenType | null;
  lane_max: number;
  id: number;
  race_no: number;
  race_run: string;
  race_class: string;
  race_start_sort: number;
  status: string;
  texte: string[];
  links: string[] | null;
  timeJs: moment.Moment | null;
  searchData: string | null;
  filetime: number;
  distance: number;
  gender: string;
  official_time: string | null;
};

export type DataRaceQueryType = {
  [key: string]: DataRaceType;
};

export type DataRaceStatusType = {
  [key: string]: string;
};