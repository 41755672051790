import { Select, SelectProps } from "antd";

type WettkampfFilterboxSportType = {
  filterOptions: SelectProps["options"];
  callback: (arg1: string[]) => void;
  filters: string[];
};

const WettkampfFilterboxSport = ({
  filterOptions,
  callback,
  filters,
}: WettkampfFilterboxSportType) => {
  return (
    <Select
      mode="multiple"
      allowClear
      style={{ width: "100%" }}
      placeholder="sport"
      onChange={callback}
      options={filterOptions}
      value={filters}
    />
  );
};

export default WettkampfFilterboxSport;
