import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import moment from "moment";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import Boat from "../Boat/Boat";
import { DataBoatType, DataRaceType } from "../helper/myTypes";
import { getDisableFlagValue } from "../PageHead/DisableFlagsView";
import htmlDecode from "../helper/htmlDecode";
import { memo } from "react";

const timeFormat = ["HH:mm:ss.SSS", "mm:ss.SSS", "ss.SSS"];

export const getTimeValue = (boat: DataBoatType) => {
  const a = boat[1];
  if (!a) {
    return -1;
  }
  const t = a[0];
  if (!t) {
    return -2;
  }
  const tJs = moment(htmlDecode(t), timeFormat);
  if (!tJs || !tJs.isValid()) {
    return -3;
  }
  return tJs.valueOf();
};

export const calcRanks = (boats: DataBoatType[]) => {
  for (let i = 0; i < boats.length; i += 1) {
    const b = boats[i];
    const bt = getTimeValue(b);
    if (bt > 0) {
      const t1 = getTimeValue(b);
      const t2 = getTimeValue(boats[0]);
      if (t1 > 0 && t2 > 0) {
        b.timeOffset = t1 - t2;
      }
    }
  }
};

type BoatlistType = {
  race: DataRaceType;
  flags: boolean;
  distanceSort: number;
};

const Boatlist = memo(function Boatlist({
  race,
  flags,
  distanceSort,
}: BoatlistType) {
  const rand = Math.random();
  const disableFlags = getDisableFlagValue();

  const { positionen, lane_max: laneMax } = race;
  const boats: DataBoatType[] = [];
  for (let i = 1; i <= laneMax; i += 1) {
    const b = race[i];
    if (b) {
      boats.push(b);
    }
  }
  let distances: string[];
  if (!positionen) {
    distances = [];
  } else {
    distances = Object.keys(positionen);
  }
  distances.sort((a, b) => parseInt(b) - parseInt(a));

  calcRanks(boats);
  return (
    <Table className="BoatsTable" key={rand}>
      <Thead>
        <Tr>
          <Th>Rank</Th>
          <Th>Lane</Th>
          {flags && !disableFlags ? <Th></Th> : null}
          <Th>Boat</Th>
          {distances.map((d) => {
            if (d === "0") {
              return null;
            }
            if (distanceSort !== 0 && race.distance > 0) {
              const di = parseInt(d, 10);
              return <Th key={d}>{(race.distance - di).toString() + "m"}</Th>;
            }
            return <Th key={d}>{d + "m"}</Th>;
          })}
          <Th>Finish</Th>
        </Tr>
      </Thead>
      <Tbody>
        {boats.map((b) => {
          if (!b || !b[0]) {
            return null;
          }
          return (
            <Boat flags={flags} key={b.bahn} boat={b} distances={distances} />
          );
        })}
      </Tbody>
    </Table>
  );
});

export default Boatlist;
