import { Row, Col, Select } from "antd";
import { DataRaceQueryType } from "../helper/myTypes";

const myGenderOptions = [
  {
    label: "Male",
    value: "m",
  },
  {
    label: "Female",
    value: "f",
  },
  {
    label: "Mix",
    value: "x",
  },
];

type RaceFiltersType = {
  races: DataRaceQueryType;
  genderFilters: string[];
  setGenderFilter: (arg1: string[]) => void;
  distanceFilters: string[];
  setDistanceFilter: (arg1: string[]) => void;
  typeFilters: string[];
  setTypeFilter: (arg1: string[]) => void;
};

const RaceFilters = ({
  races,
  genderFilters,
  setGenderFilter,
  distanceFilters,
  setDistanceFilter,
  typeFilters,
  setTypeFilter,
}: RaceFiltersType) => {
  if (races) {
    const myDistanceOptions = Object.keys(races).map((k) =>
      races[k] && races[k].distance ? races[k].distance : -1
    );
    const myUniqueDistanceOptions = myDistanceOptions
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a - b)
      .map((c) => {
        return { label: `${c}m`, value: c.toString() };
      });

    const myTypeOptions = Object.keys(races).map((k) =>
      races[k] && races[k].race_run ? races[k].race_run : "Unknown"
    );
    const myUniqueTypeOptions = myTypeOptions
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b))
      .map((c) => {
        return { label: c, value: c };
      });
    return (
      <Row>
        <Col xs={24} md={8}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Gender"
            onChange={setGenderFilter}
            options={myGenderOptions}
            value={genderFilters}
          />
        </Col>
        <Col xs={24} md={8}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Distance"
            onChange={setDistanceFilter}
            options={myUniqueDistanceOptions}
            value={distanceFilters}
          />
        </Col>
        <Col xs={24} md={8}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Run"
            onChange={setTypeFilter}
            options={myUniqueTypeOptions}
            value={typeFilters}
          />
        </Col>
      </Row>
    );
  }
  return null;
};

export default RaceFilters;
