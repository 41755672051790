const htmlDecode = (input: string): string => {
  /*
  const doc = new DOMParser().parseFromString(input, "text/html");
  const txt = doc.documentElement.textContent;
  if (!txt) {
    return "";
  }
  return txt;
*/
  if (!input) {
    return "";
  }
  return input
    .replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
    .replace(/&#x([0-9A-Fa-f]+);/g, (match, hex) => String.fromCharCode(parseInt(hex, 16)));
};

export default htmlDecode;
