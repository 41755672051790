import { Empty, Tabs } from "antd";
import moment from "moment";
import React, { memo } from "react";

import { getDay, timestampToMoment } from "../helper/timestampToString";
import Racelist from "./Racelist";
import htmlDecode from "../helper/htmlDecode";
import { DataRaceQueryType, DataRaceType } from "../helper/myTypes";
import { useQueryParam } from "use-query-params";
import { ParamRace, ParamRaceType } from "../helper/queryParamNames";

const keyFormat = "dd, DD.MM";

type TabDataType = {
  key: string;
  label: string;
};
type TabrendererType = {
  tab: string | null;
  tabData: TabDataType[];
};
type TabrendererStateType = {
  tab: string;
};

type TabRendererElementType = {
  tab: string;
  tabData: TabDataType[];
  setTab: (arg1: string) => void;
};

const TabRendererElement = memo(function TabRendererElement({
  tab,
  tabData,
  setTab,
}: TabRendererElementType) {
  return (
    <div>
      <Tabs activeKey={tab} items={tabData} onChange={setTab} />
    </div>
  );
});

class Tabrenderer extends React.Component<
  TabrendererType,
  TabrendererStateType
> {
  state = { tab: "" };

  reTab = false;

  initOpenTab = () => {
    const { tab: tab1, tabData } = this.props;
    this.reTab = false;
    // Go to Tab from Race
    if (tab1) {
      this.setState({ tab: tab1 });
      return;
    }

    // Go to Tab from today
    const today = moment().format(keyFormat);
    if (tabData.findIndex((t) => t.key === today) >= 0) {
      this.setState({ tab: today });
      return;
    }

    // Go to First Tab
    if (tabData[0]) {
      this.setState({ tab: tabData[0].key });
      return;
    }

    // Select no tab
    this.reTab = true;
    return null;
  };

  componentDidMount = () => {
    const { tabData } = this.props;
    if (!tabData || tabData.length === 0) {
      this.reTab = true;
      return;
    }
    this.initOpenTab();
  };

  componentDidUpdate = (prevProps: TabrendererType) => {
    if (this.reTab) {
      this.initOpenTab();
    }
    const { tab: tab1, tabData } = this.props;
    const { tab: tab2 } = prevProps;
    if (tab1 && tab1 !== tab2) {
      if (tabData.findIndex((t) => t.key === tab1) < 0) {
        return;
      }
      this.setState({ tab: tab1 });
    }
  };

  setTab = (tab: string) => {
    this.setState({ tab });
  };

  render = () => {
    const { tab } = this.state;
    const { tabData } = this.props;
    return (
      <TabRendererElement tab={tab} tabData={tabData} setTab={this.setTab} />
    );
  };
}

type RacelistWrapperType = {
  races: DataRaceQueryType;
  getRace: (arg1: number) => void;
  flags: boolean;
  distanceSort: number;
  searchValue: string;
  currentRace?: number | null | undefined;
  genderFilter: string[];
  distanceFilter: string[];
  typeFilter: string[];
};

type DaysType = {
  [key: string]: DataRaceType[];
};

type SearchRacesType = [boolean, DataRaceType[]];

class RacelistWrapperReal extends React.Component<RacelistWrapperType> {
  getTabFromRace = (rID: number, races: DataRaceType[]) => {
    for (let i = 0; i < races.length; i += 1) {
      const r = races[i];
      if (String(r.id) === String(rID)) {
        return getDay(r.timeJs);
      }
    }
    return null;
  };

  getTab = (rID: number | null | undefined, races: DataRaceType[]) => {
    let tab = null;
    if (rID) {
      tab = this.getTabFromRace(rID, races);
    }
    return tab;
  };

  getRaces = () => {
    const { races } = this.props;
    if (!races) {
      const myRaces: DataRaceType[] = [];
      return myRaces;
    }
    const myRaces = Object.keys(races)
      .filter((k) => races[k] !== null)
      .map((k) => {
        const r = races[k];
        return {
          ...r,
          id: parseInt(k),
          timeJs: timestampToMoment(r.race_start_sort),
        };
      });
    return myRaces;
  };

  sortRaces = (races: DataRaceType[]) => {
    races.sort((a, b) => {
      if (!a.timeJs || !a.timeJs.isValid()) {
        return -1;
      }
      if (!b.timeJs || !b.timeJs.isValid()) {
        return 1;
      }
      return a.timeJs.diff(b.timeJs);
    });
  };

  splitByDay = (races: DataRaceType[]): DaysType => {
    const days: DaysType = {};
    for (let i = 0; i < races.length; i += 1) {
      const r = races[i];
      const d = getDay(r.timeJs);
      if (!days[d]) {
        days[d] = [];
      }
      days[d].push(r);
    }
    return days;
  };

  daysToTabdata = (days: DaysType, allRaces: DataRaceType[]) => {
    const { getRace, flags, distanceSort } = this.props;
    const tabData = [];
    const keys = Object.keys(days);
    for (let i = 0; i < keys.length; i += 1) {
      const k = keys[i];
      const d = days[k];
      const t = {
        label: k,
        key: k,
        children: (
          <Racelist
            flags={flags}
            distanceSort={distanceSort}
            getRace={getRace}
            races={d}
            allRaces={allRaces}
          />
        ),
      };
      tabData.push(t);
    }
    return tabData;
  };

  searchRaces = (races: DataRaceType[]): SearchRacesType => {
    const { searchValue } = this.props;
    if (!searchValue || searchValue === "") {
      return [false, races];
    }
    const searchValueN = searchValue.toLowerCase();
    const parts = searchValueN.split(" ");

    const myRaces = races.filter((r) => {
      let found = true;
      if (!r.searchData) {
        let dataStr = r.race_class + " " + r.race_no + " " + r.race_run + " ";
        const { lane_max: laneMax } = r;
        for (let i = 0; i <= laneMax; i += 1) {
          const b = r[i];
          if (b) {
            dataStr += b[0];
          }
        }
        dataStr = htmlDecode(dataStr);
        dataStr = dataStr.toLowerCase();
        r.searchData = dataStr;
      }
      const { searchData } = r;
      parts.forEach((p) => {
        if (!searchData.includes(p)) {
          found = false;
        }
      });
      if (found) {
        return true;
      }
      return false;
    });
    return [true, myRaces];
  };

  filterRaces = (races: DataRaceType[]) => {
    const { genderFilter, distanceFilter, typeFilter } = this.props;
    const myRaces = races.filter((r) => {
      if (genderFilter.length > 0) {
        if (!genderFilter.includes(r.gender)) {
          return false;
        }
      }
      if (distanceFilter.length > 0) {
        if (!distanceFilter.includes(r.distance.toString())) {
          return false;
        }
      }
      if (typeFilter.length > 0) {
        if (!typeFilter.includes(r.race_run)) {
          return false;
        }
      }
      return true;
    });
    return myRaces;
  };

  render = () => {
    const { currentRace, getRace, flags, distanceSort } = this.props;
    const racesAll = this.getRaces();
    racesAll.sort((a, b) => {
      if (!a) {
        return -1;
      }
      if (!b) {
        return 1;
      }
      return a.race_start_sort - b.race_start_sort;
    });
    const races = this.filterRaces(racesAll);
    const [active, searchedRaces] = this.searchRaces(races);
    const days = this.splitByDay(searchedRaces);
    const tab = this.getTab(currentRace, searchedRaces);
    const dayView = localStorage.getItem("dayview");
    if (racesAll.length === 0 || Object.keys(days).length === 0) {
      return <Empty />;
    }
    if (dayView === "true" || Object.keys(days).length === 1 || active) {
      return (
        <Racelist
          flags={flags}
          distanceSort={distanceSort}
          getRace={getRace}
          races={searchedRaces}
          allRaces={racesAll}
        />
      );
    }
    const tabData = this.daysToTabdata(days, races);
    return <Tabrenderer tabData={tabData} tab={tab} />;
  };
}

const RacelistWrapper = (props: RacelistWrapperType) => {
  const [race] = useQueryParam(ParamRace, ParamRaceType);
  return <RacelistWrapperReal {...props} currentRace={race} />;
};

export default RacelistWrapper;
