import React from "react";
import { DataRaceType } from "../helper/myTypes";
import Race from "./Race";
import moment from "moment";

type RaceWrapperType = {
  race: DataRaceType;
  races: DataRaceType[];
  flags: boolean;
  distanceSort: number;
  getRace: (arg1: number) => void;
};
export default class RaceWrapper extends React.Component<RaceWrapperType> {
  private ref = React.createRef<HTMLDivElement>();

  private refreshTimer: ReturnType<typeof setTimeout> | null = null;

  componentDidMount = () => {
    this.refreshRace();
    this.activateAutorefresh();
    setTimeout(this.scrollAction, 300);
  };

  scrollAction = () => {
    const { ref } = this;
    if (ref) {
      const { current } = ref;
      if (current) {
        window.requestAnimationFrame(() => {
          current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        });
      }
    }
  };

  componentWillUnmount = () => {
    const { refreshTimer } = this;
    if (refreshTimer) {
      clearTimeout(refreshTimer);
    }
  };

  activateAutorefresh = async () => {
    const { race } = this.props;
    if (race) {
      const { status, timeJs } = race;
      if (status && status !== "SO") {
        if (timeJs && timeJs.isValid()) {
          if (moment().isSame(timeJs, "day")) {
            this.refreshTimer = setTimeout(this.autorefresh, 2000);
          }
        }
      }
    }
  };

  autorefresh = async () => {
    const { race } = this.props;
    if (race) {
      const { status } = race;
      if (status && status !== "SO") {
        await this.refreshRace();
        if (this.ref.current) {
          this.refreshTimer = setTimeout(this.autorefresh, 2000);
        }
      }
    }
  };

  refreshRace = async () => {
    const { getRace, race } = this.props;
    getRace(race.id);
  };

  render = () => {
    const { race, races, flags, distanceSort } = this.props;
    return (
      <div ref={this.ref} style={{ scrollMarginTop: "100px" }}>
        <Race
          flags={flags}
          race={race}
          races={races}
          distanceSort={distanceSort}
        />
      </div>
    );
  };
}
