import { Alert, Row, Typography } from "antd";
import { memo } from "react";
import { useQueryParam } from "use-query-params";
import baseurl from "../helper/baseurl";
import { DataRaceType } from "../helper/myTypes";
import { ParamWkOrg, ParamWkOrgType } from "../helper/queryParamNames";
import { isLocalUrl } from "../Wettkampf/WettkampfLinks";
import htmlDecode from "../helper/htmlDecode";

type RaceLinksType = {
  race: DataRaceType;
};

const RaceLinks = memo(function RaceLinks({ race }: RaceLinksType) {
  const [wkOrg] = useQueryParam(ParamWkOrg, ParamWkOrgType);
  const { links } = race;
  if (!links || links.length === 0) {
    return null;
  }
  return (
    <Alert
      type="warning"
      style={{ marginBottom: "5px" }}
      message={
        <>
          {links.map((l) => {
            const [url, title] = l.split("|");
            if (!url || !title) {
              return null;
            }
            let myUrl = "";
            if (isLocalUrl(url)) {
              myUrl = baseurl + "/" + wkOrg + "/" + url;
            } else {
              myUrl = url;
            }
            return (
              <Row key={l}>
                <Typography.Link href={myUrl} target="_blank">
                  {htmlDecode(title)}
                </Typography.Link>
              </Row>
            );
          })}
        </>
      }
    />
  );
});

export default RaceLinks;
