import baseurl, { debugParser } from "./baseurl";
import handleErrors from "./fetchHelper";
import { DataRaceStatusType } from "./myTypes";
import offlineWarning, { removeWarning } from "./offlineWarning";

const parseRaceStatus = async (wkID: string, wkOrg: string) => {
  const url = encodeURI(
    baseurl +
      "/API_IMAS_race_status.php" +
      "?wettkampfOrg=" +
      wkOrg +
      "&competition=" +
      wkID
  );
  try {
    const data = await fetch(url).then(handleErrors);
    const content = await data.text();
    const raceStatus: DataRaceStatusType = JSON.parse(content);
    removeWarning();
    return raceStatus;
  } catch (e) {
    if (debugParser) {
      console.warn(e, url);
    }
    offlineWarning();
    return null;
  }
};

export default parseRaceStatus;
