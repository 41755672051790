import { Image } from "antd";
import { useQueryParam } from "use-query-params";
import baseurl from "../helper/baseurl";
import {
  ParamWkID,
  ParamWkIDType,
  ParamWkOrg,
  ParamWkOrgType,
} from "../helper/queryParamNames";

const WettkampfHeader = () => {
  const [wkID] = useQueryParam(ParamWkID, ParamWkIDType);
  const [wkOrg] = useQueryParam(ParamWkOrg, ParamWkOrgType);
  const url = `${baseurl}/${wkOrg}/${wkID}/${wkID}_header.jpg`;
  const url2 = `${baseurl}/${wkOrg}/header.jpg`;
  return (
    <div className="CompetitionHeader">
      <Image
        preview={false}
        src={url}
        fallback={url2}
        style={{ maxHeight: "10vh", objectFit: "contain" }}
      />
    </div>
  );
};

export default WettkampfHeader;
