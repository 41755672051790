import { Alert, Row, Typography } from "antd";
import { useQueryParam } from "use-query-params";
import baseurl from "../helper/baseurl";
import { DataCompetitionType } from "../helper/myTypes";
import { ParamWkOrg, ParamWkOrgType } from "../helper/queryParamNames";
import htmlDecode from "../helper/htmlDecode";

type WettkampfLinksType = {
  competition: DataCompetitionType;
};

export const isLocalUrl = (url: string) => {
  if (url.match(/^wettkampf_\d+\//g)) {
    return true;
  }
  return false;
};

const WettkampfLinks = ({ competition }: WettkampfLinksType) => {
  const [wkOrg] = useQueryParam(ParamWkOrg, ParamWkOrgType);
  const { links } = competition;
  if (!links || links.length === 0) {
    return null;
  }
  return (
    <Alert
      type="warning"
      style={{ marginBottom: "5px" }}
      message={
        <>
          {links.map((l, i) => {
            const [url, title] = l.split("|");
            if (!url || !title) {
              return null;
            }
            let myUrl = "";
            if (isLocalUrl(url)) {
              myUrl = baseurl + "/" + wkOrg + "/" + url;
            } else {
              myUrl = url;
            }
            return (
              <Row key={i}>
                <Typography.Link href={myUrl} target="_blank">
                  {htmlDecode(title)}
                </Typography.Link>
              </Row>
            );
          })}
        </>
      }
    />
  );
};

export default WettkampfLinks;
