import { Image, Typography, Row, List, Col, Button, SelectProps } from "antd";
import moment from "moment";
import React from "react";
import { DataCompetitionType } from "../helper/myTypes";
import parseCompetitionList from "../helper/parseCompetitionList";
import CompetitionItem from "./CompetitionItem";
import Werbung from "./Werbung";
import WettkampfFilterboxNOC from "./WettkampfFilterboxNOC";
import WettkampfFilterboxSport from "./WettkampfFilterboxSport";
import WettkampfSearchbar from "./WettkampfSearchbar";

import IMAS_SPSY1 from "../../assets/IMAS.svg";

type CompListType = {
  comps: DataCompetitionType[];
};

const CompList = ({ comps }: CompListType) => {
  return (
    <List
      split={false}
      size="small"
      dataSource={comps}
      renderItem={(item) => <CompetitionItem comp={item} />}
    />
  );
};

type WettkampflisteType = Record<string, never>;
type WettkampflisteStateType = {
  comps: DataCompetitionType[];
  searchValue: string;
  showAll: boolean;
  filtersNOC: string[];
  filtersSport: string[];
};

type ShowAllButtonType = {
  callback: () => void;
};

const ShowAllButton = ({ callback }: ShowAllButtonType) => {
  return (
    <div
      style={{ marginTop: "10px", paddingRight: "15px", paddingLeft: "15px" }}
    >
      <Button
        style={{ paddingTop: "10ox" }}
        onClick={callback}
        block
        type="primary"
      >
        Show More
      </Button>
    </div>
  );
};

export default class Wettkampfliste extends React.Component<
  WettkampflisteType,
  WettkampflisteStateType
> {
  constructor(props: WettkampflisteType) {
    super(props);
    const comps = localStorage.getItem("comps");
    const filtersNOC = localStorage.getItem("filtersNOC");
    const filtersSport = localStorage.getItem("filtersSport");
    this.state = {
      comps: comps ? JSON.parse(comps) : [],
      filtersNOC: filtersNOC ? JSON.parse(filtersNOC) : ["INT"],
      filtersSport: filtersSport ? JSON.parse(filtersSport) : [],
      searchValue: "",
      showAll: false,
    };
  }

  setShowAll = () => {
    this.setState({ showAll: true });
  };

  setSearchValue = (searchValue: string) => {
    this.setState({ searchValue });
  };

  componentDidUpdate = () => {
    const { comps } = this.state;
    if (comps) {
      localStorage.setItem("comps", JSON.stringify(comps));
    }
  };

  componentDidMount = async () => {
    const comps = await parseCompetitionList();
    if (comps) {
      this.setState({ comps });
    }
  };

  setNOCFilter = (options: string[]) => {
    this.setState({ filtersNOC: options });
    localStorage.setItem("filtersNOC", JSON.stringify(options));
    return;
  };

  getAllNOC = () => {
    const { comps } = this.state;
    const noc: string[] = [];
    comps.forEach((c) => {
      if (!noc.includes(c.noc)) {
        noc.push(c.noc);
      }
    });
    return noc;
  };

  setSportFilter = (options: string[]) => {
    this.setState({ filtersSport: options });
    localStorage.setItem("filtersSport", JSON.stringify(options));
    return;
  };

  getAllSportOptions = () => {
    const { comps } = this.state;
    const value: SelectProps["options"] = [];
    comps.forEach((c) => {
      if (!value.find((v) => v.value === c.sports)) {
        value.push({
          label: c.sportsname === "" ? "Other" : c.sportsname,
          value: c.sports,
        });
      }
    });
    return value;
  };

  searchCompetitions = (comps: DataCompetitionType[]) => {
    const { searchValue } = this.state;
    if (!searchValue || searchValue === "") {
      return comps;
    }
    const searchValueN = searchValue.toLowerCase();
    const parts = searchValueN.split(" ");

    const myComps = comps.filter((r) => {
      let found = true;
      let dataStr = r.name + " " + r.regattastrecke;
      dataStr = dataStr.toLowerCase();
      parts.forEach((p) => {
        if (!dataStr.includes(p)) {
          found = false;
        }
      });
      if (found) {
        return true;
      }
      return false;
    });
    return myComps;
  };

  getYearFromComp = (comp: DataCompetitionType) => {
    const { from } = comp;
    const fromJs = moment(from, "dddd DD.MM.YYYY");
    if (fromJs && fromJs.isValid()) {
      return fromJs.year();
    }
    return 0;
  };

  getCompsFromYear = (comps: DataCompetitionType[], year: number) => {
    const myComps: DataCompetitionType[] = [];
    comps.forEach((c) => {
      const y = this.getYearFromComp(c);
      if (y === year) {
        myComps.push(c);
      }
    });
    return myComps;
  };

  getCompsThisYear = (comps: DataCompetitionType[]) => {
    return this.getCompsFromYear(comps, moment().year());
  };

  getCompsLastYear = (comps: DataCompetitionType[]) => {
    return this.getCompsFromYear(comps, moment().year()).concat(
      this.getCompsFromYear(comps, moment().year() - 1)
    );
  };

  compIsNOC = (comp: DataCompetitionType, NOC: string[]) => {
    if (NOC.length === 0) {
      return true;
    }
    for (let i = 0; i < NOC.length; i += 1) {
      const n = NOC[i];
      if (comp.noc === n) {
        return true;
      }
    }
    return false;
  };

  compIsSport = (comp: DataCompetitionType, sport: string[]) => {
    if (sport.length === 0) {
      return true;
    }
    for (let i = 0; i < sport.length; i += 1) {
      const n = sport[i];
      if (comp.sports === n) {
        return true;
      }
    }
    return false;
  };

  filterCompetitions = (comps: DataCompetitionType[]) => {
    const { filtersNOC, filtersSport } = this.state;
    const myComps: DataCompetitionType[] = [];
    comps.forEach((c) => {
      if (this.compIsNOC(c, filtersNOC) && this.compIsSport(c, filtersSport)) {
        myComps.push(c);
      }
    });
    return myComps;
  };

  render = () => {
    const { comps, searchValue, showAll, filtersNOC, filtersSport } =
      this.state;
    let myComps: DataCompetitionType[] = [];
    let myCompsAll: DataCompetitionType[] = [];
    // filter By Searchvalue
    if (searchValue !== "") {
      myComps = this.searchCompetitions(comps);
    }
    //filter by filtertags
    else {
      myCompsAll = this.filterCompetitions(comps);
      myComps = myCompsAll;
      if (!showAll) {
        const tmp = this.getCompsThisYear(myComps);
        if (tmp.length <= 3) {
          const tmp2 = this.getCompsLastYear(myComps);
          if (tmp2.length > 0) {
            myComps = tmp2;
          }
        } else {
          myComps = tmp;
        }
      }
    }
    return (
      <div style={{ padding: "10px" }}>
        <Row justify="space-between" align="middle">
          <Col xs={24} md={18}>
            <Typography.Title>
              Regattas, done with IMAS-Timesystem:
            </Typography.Title>
          </Col>
          <Col id="IMAS_LOGO_DIV" xs={24} md={6}>
            <Image
              preview={false}
              src={IMAS_SPSY1}
              alt="logo"
              style={{ height: "15vh" }}
            />
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col
            style={{ paddingLeft: "16px", paddingRight: "16px" }}
            xs={24}
            xl={18}
          >
            <Row>
              <Col xs={24} md={12}>
                <WettkampfFilterboxNOC
                  filterOptions={this.getAllNOC()}
                  callback={this.setNOCFilter}
                  filters={filtersNOC}
                />
              </Col>
              <Col xs={24} md={12}>
                <WettkampfFilterboxSport
                  filterOptions={this.getAllSportOptions()}
                  callback={this.setSportFilter}
                  filters={filtersSport}
                />
              </Col>
            </Row>
            <WettkampfSearchbar
              searchValue={searchValue}
              setSearchValue={this.setSearchValue}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} xl={18}>
            <CompList comps={myComps} />
            {!showAll &&
            searchValue === "" &&
            myCompsAll.length > myComps.length ? (
              <ShowAllButton callback={this.setShowAll} />
            ) : null}
          </Col>
          <Col xs={24} xl={6} style={{ width: "100%" }}>
            <Werbung />
          </Col>
        </Row>
      </div>
    );
  };
}
