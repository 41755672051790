import { Checkbox, Row } from "antd";
import React from "react";

const StorageKey = "dayview";

const DisableDayView = () => {
  const [dayView, setDayView] = React.useState(
    localStorage.getItem(StorageKey)
  );
  return (
    <Row>
      <Checkbox
        onChange={({ target: { checked } }) => {
          const s = checked.toString();
          localStorage.setItem(StorageKey, s);
          setDayView(s);
          window.location.reload();
        }}
        checked={dayView === "true"}
      >
        Disable Dayview
      </Checkbox>
    </Row>
  );
};

export default DisableDayView;
