import { BooleanParam, NumberParam, StringParam } from "use-query-params";

export const ParamWkID = "competition";
export const ParamWkIDType = StringParam;

export const ParamWkOrg = "org";
export const ParamWkOrgType = StringParam;

export const ParamRace = "race";
export const ParamRaceType = NumberParam;

export const ParamGraph = "graph";
export const ParamGraphType = BooleanParam;
