import {
  ResponsiveContainer,
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ZAxis,
  Scatter,
  LabelList,
  Label,
  Cell,
  Tooltip,
} from "recharts";
import moment from "moment";
import {
  DataCleanedBoatType,
  DataCleanedBoatTypeToString,
  DataRaceType,
} from "../helper/myTypes";
import { ScatterData } from "./SpeedGraph";
import colors from "./Colors";
import { cleanedBoatData } from "../Boat/Boat";
import { Typography } from "antd";
import htmlDecode from "../helper/htmlDecode";

type ScattergraphType = {
  data: ScatterData[];
  races: DataRaceType[];
  allBoats: string[];
};

const getBoatIndex = (allBoats: string[], boat: DataCleanedBoatType) => {
  const myValue = DataCleanedBoatTypeToString(boat);
  const index = allBoats.indexOf(myValue);
  return index;
};

type PayloadType = {
  dataKey: string;
  name: string;
  value: number;
  payload: ScatterData;
};

type CustomToolTipType = {
  active?: boolean;
  payload?: PayloadType[];
  race: DataRaceType;
};

const CustomToolTip = ({ active, payload, race }: CustomToolTipType) => {
  if (active) {
    if (payload?.length === 2) {
      let boat = null;
      for (let i = 0; i <= race.lane_max; i += 1) {
        const b = race[i];
        if (b && b.bahn === payload[1].value.toString()) {
          boat = b;
        }
      }
      if (boat) {
        const team = cleanedBoatData(boat[0]);
        return (
          <div className="scattergraphTooltip">
            <Typography.Title level={5} className="label">
              {team.team}
            </Typography.Title>
            <Typography.Text strong className="label">
              {payload[0].payload.t}
            </Typography.Text>
            <br></br>
            {team.athletes.map((a) => {
              return (
                <div key={a}>
                  <Typography.Text className="label">{a}</Typography.Text>
                  <br></br>
                </div>
              );
            })}
          </div>
        );
      }
    }
  }
  return null;
};

const Scattergraph = ({ data, races, allBoats }: ScattergraphType) => {
  let xMin = 999999999999999;
  let xMax = 0;
  for (let i = 0; i < data.length; i += 1) {
    const d = data[i];
    const { tJs } = d;
    if (tJs > xMax) {
      xMax = tJs;
    }
    if (tJs < xMin) {
      xMin = tJs;
    }
  }
  return (
    <div>
      {races.map((r, rIndex) => {
        const myData = data.filter((d) => d.r === r.race_run);

        return (
          <ResponsiveContainer width="100%" height={140} key={rIndex}>
            <ScatterChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                dataKey="tJs"
                name="time"
                unit=""
                domain={[xMin - 500, xMax + 500]}
                tickFormatter={(value) => {
                  const t = moment(value);
                  return t.format("mm:ss");
                }}
              />
              <YAxis
                dataKey="l"
                name="race"
                unit=""
                type="number"
                domain={["dataMin - 1", "dataMax + 1"]}
                tick={false}
              >
                <Label
                  fill="red"
                  position={r.race_run.length > 15 ? "insideTopLeft" : "center"}
                  value={htmlDecode(r.race_run)}
                  angle={r.race_run.length > 15 ? 0 : -60}
                />
              </YAxis>
              <ZAxis range={[500, 500]} />
              <Scatter shape="square" data={myData}>
                {myData.map((entry, index) => {
                  const boatIndex = getBoatIndex(allBoats, entry.data);
                  let col = "#8681f6";
                  let col2 = "#000000";
                  if (boatIndex >= 0) {
                    col = colors[boatIndex][0];
                    col2 = colors[boatIndex][4];
                  }
                  return (
                    <Cell key={`cell-${index}`} fill={col} stroke={col2} />
                  );
                })}
                <LabelList
                  style={{ pointerEvents: "none" }}
                  dataKey="l"
                  position="center"
                />
              </Scatter>
              <Tooltip content={<CustomToolTip race={r} />} />
            </ScatterChart>
          </ResponsiveContainer>
        );
      })}
    </div>
  );
};

export default Scattergraph;
