import { useQueryParam } from "use-query-params";
import baseurl from "../helper/baseurl";
import {
  ParamWkID,
  ParamWkIDType,
  ParamWkOrg,
  ParamWkOrgType,
} from "../helper/queryParamNames";

const WettkampfCss = () => {
  const [wkID] = useQueryParam(ParamWkID, ParamWkIDType);
  const [wkOrg] = useQueryParam(ParamWkOrg, ParamWkOrgType);
  const url1 = `${baseurl}/${wkOrg}/${wkID}/${wkID}_pwa.css`;
  const url2 = `${baseurl}/${wkOrg}/pwa.css`;
  const url3 = `${baseurl}/pwa.css`;
  return (
    <>
      <link rel="stylesheet" href={url3} />
      <link rel="stylesheet" href={url2} />
      <link rel="stylesheet" href={url1} />
    </>
  );
};

export default WettkampfCss;
